// src/utils/apiHelpers.js
import api from '../services/api';

export const fetchTransactions = async (from, to) => {
  try {
    const response = await api.post('/cashier', {
      filter: {
        date: { from, to }
      }
    });

    let inAmount = 0;
    let outAmount = 0;
    let depositTypes = ['2', 'Deposit', 'إيداع', 'Dépôt'];
    let withdrawTypes = ['3', 'Withdraw','سحب','Retirer'];
    response.data.result.records.forEach(transaction => {
      if (withdrawTypes.includes(transaction.type)) {
        inAmount += parseFloat(transaction.amount);
      } else if (depositTypes.includes(transaction.type)) {
        outAmount += parseFloat(transaction.amount);
      }
    });

    return { in: inAmount, out: outAmount };
  } catch (error) {
    console.error('Error fetching transactions:', error);
    throw error;
  }
};
