import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import { useHistory } from 'react-router-dom'; // Import useHistory

const TotalPlayersCount = ({ totalPlayers, sx }) => {
  const history = useHistory(); // Initialize useHistory

  const handleCardClick = () => {
    history.push('/players'); // Navigate to the players list
  };

  return (
    <Card
      onClick={handleCardClick}
      sx={{
        minHeight: '150px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
        ...sx,
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center">
          <PeopleIcon sx={{ fontSize: 50, color: process.env.REACT_APP_PRIMARY_COLOR || process.env.REACT_APP_PRIMARY_COLOR }} />
          <Box ml={2}>
            <Typography variant="h6">Total Players Count</Typography>
            <Typography variant="h4">{totalPlayers}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TotalPlayersCount;
