const betStatusOptions = [
    { label: 'Select All', value: 'selectAll' },
    { label: 'Waiting Partner', value: -2 },
    { label: 'Rejected', value: -1 },
    { label: 'Pending', value: 0 },
    { label: 'Accepted', value: 1 },
    { label: 'Returned', value: 2 },
    { label: 'Lost', value: 3 },
    { label: 'Won', value: 4 },
    { label: 'Cashed out', value: 5 }
  ];
  export default betStatusOptions;