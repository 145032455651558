import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import moment from 'moment';
import {
  Container,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  useMediaQuery
} from '@mui/material';
import Layout from './Layout';
import api from '../services/api';
import DynamicListComponent from './DynamicListComponent';
import ExpandMoreIcon from '@mui/icons-material/ExpandLess';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DailyPlayerTransactions from './DailyPlayerTransactions';

const formatCurrency = (value) => {
  return `${parseFloat(value).toLocaleString('fr-FR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} TND`;
};

const getFirstDayOfMonth = () => {
  return moment().startOf('month').toDate();
};

const getTomorrowDate = () => {
  return moment().add(1, 'days').startOf('day').toDate();
};

const formatDateForFilter = (date) => {
  return moment(date).format('YYYY-MM-DDTHH:mm:ss');
};

const formatDateForApi = (date) => {
  return moment(date).format('YYYY/MM/DD HH:mm:ss');
};

const cleanFormFilters = (filters) => {
  const cleanedFilters = {};
  if (filters.transactionId) {
    cleanedFilters.transactionId = { action: '=', value: filters.transactionId, valueLabel: filters.transactionId };
  }
  if (filters.userName) {
    cleanedFilters.userName = { action: '=', value: filters.userName, valueLabel: filters.userName };
  }
  if (filters.playerId) {
    cleanedFilters.playerId = { action: '=', value: filters.playerId, valueLabel: filters.playerId };
  }
  if (filters.date.from && filters.date.to) {
    cleanedFilters.date = {
      from: filters.date.from,
      to: filters.date.to,
      action: 'between',
      valueLabel: `${filters.date.from} - ${filters.date.to}`
    };
  }
  return cleanedFilters;
};

const cleanApiFilters = (filters, defaultApiFilters) => {
  const cleanedFilters = { ...filters };
  Object.keys(cleanedFilters).forEach(key => {
    if (!cleanedFilters[key].value) {
      delete cleanedFilters[key];
    }
  });
  cleanedFilters.timezone = defaultApiFilters.timezone;
  cleanedFilters.currency = defaultApiFilters.currency;
  cleanedFilters.affiliateId = defaultApiFilters.affiliateId;
  cleanedFilters.date = filters.date;
  cleanedFilters.isToMe = filters.isToMe;
  return cleanedFilters;
};

const TransactionsList = () => {
  const { user, logout } = useContext(AuthContext);
  const [transactions, setTransactions] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [goToPage, setGoToPage] = useState('');
  const location = useLocation();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [sortConfig, setSortConfig] = useState({ title: null, status: 'asc' });
  const [formFilters, setFormFilters] = useState({
    transactionId: '',
    userName: '',
    playerId: '',
    date: {
      from: '',
      to: ''
    }
  });

  const defaultApiFilters = {
    timezone: {
      action: "=",
      value: "Africa/Tunis",
      valueLabel: "(UTC+01:00) - Africa/Tunis"
    },
    currency: {
      action: "=",
      value: "TND",
      valueLabel: "TND"
    },
    affiliateId: {
      action: "=",
      value: user ? user.affiliateId : null,
      valueLabel: user ? user.affiliateId : null
    },
    isToMe: {
      action: "=",
      value: false,
      valueLabel: false
    }
  };

  const [apiFilters, setApiFilters] = useState({
    ...defaultApiFilters
  });
  const [isToMe, setIsToMe] = useState(false);

  const isPortrait = useMediaQuery('(orientation: portrait)');

  useEffect(() => {
    if (isPortrait) {
      setFiltersVisible(false);
    }
  }, [isPortrait]);

  useEffect(() => {
    const applyFiltersFromState = () => {
      if (location.state && location.state.filter) {
        const filterState = location.state.filter;
        const newFormFilters = {
          transactionId: '',
          userName: '',
          playerId: '',
          date: {
            from: formatDateForFilter(filterState.date.from),
            to: formatDateForFilter(filterState.date.to)
          }
        };
        const newApiFilters = {
          ...defaultApiFilters,
          date: {
            from: formatDateForApi(filterState.date.from),
            to: formatDateForApi(filterState.date.to),
            action: 'between',
            valueLabel: `${formatDateForApi(filterState.date.from)} - ${formatDateForApi(filterState.date.to)}`
          }
        };

        setFormFilters(newFormFilters);
        setApiFilters(cleanApiFilters(newApiFilters, defaultApiFilters));
        fetchTransactions(cleanApiFilters(newApiFilters, defaultApiFilters), sortConfig);
      } else {
        const newFormFilters = {
          transactionId: '',
          userName: '',
          playerId: '',
          date: {
            from: formatDateForFilter(getFirstDayOfMonth()),
            to: formatDateForFilter(getTomorrowDate())
          }
        };
        setFormFilters(newFormFilters);
        setApiFilters(cleanApiFilters({
          ...defaultApiFilters,
          date: {
            from: formatDateForApi(getFirstDayOfMonth()),
            to: formatDateForApi(getTomorrowDate()),
            action: 'between',
            valueLabel: `${formatDateForApi(getFirstDayOfMonth())} - ${formatDateForApi(getTomorrowDate())}`
          }
        }, defaultApiFilters));
        fetchTransactions(cleanApiFilters({
          ...defaultApiFilters,
          date: {
            from: formatDateForApi(getFirstDayOfMonth()),
            to: formatDateForApi(getTomorrowDate()),
            action: 'between',
            valueLabel: `${formatDateForApi(getFirstDayOfMonth())} - ${formatDateForApi(getTomorrowDate())}`
          }
        }, defaultApiFilters), sortConfig);
      }
    };

    applyFiltersFromState();
  }, [location.state]);

  useEffect(() => {
    if (!isInitialLoad) {
      fetchTransactions(apiFilters, sortConfig);
    } else {
      setIsInitialLoad(false);
    }
  }, [apiFilters, sortConfig, currentPage, pageSize]);

  const fetchTransactions = async (filters, sortConfig) => {
    try {
      const response = await api.post(
        '/transactions/list',
        {
          start: (currentPage - 1) * pageSize,
          limit: pageSize,
          filter: Object.keys(filters).length > 0 ? filters : {},
          sort: sortConfig.title ? sortConfig : undefined
        }
      );
      setTransactions(response.data.result.records);
      setTotalRecords(response.data.result.totalRecordsCount);
    } catch (error) {
      console.error('Error fetching transactions:', error);
      if (error.response && error.response.status === 401) {
        await logout();
      }
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFormFilters((prevFormFilters) => ({
      ...prevFormFilters,
      [name]: value
    }));
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = moment(value).format('YYYY-MM-DD HH:mm:ss');
    setFormFilters((prevFormFilters) => ({
      ...prevFormFilters,
      date: {
        ...prevFormFilters.date,
        [name]: formattedValue
      }
    }));
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    const cleanedFilters = cleanApiFilters({ ...cleanFormFilters(formFilters), ...defaultApiFilters }, defaultApiFilters);
    setApiFilters(cleanedFilters);
    setCurrentPage(1);
  };

  const handleResetFilters = () => {
    const resetFormFilters = {
      transactionId: '',
      userName: '',
      playerId: '',
      date: {
        from: formatDateForFilter(getFirstDayOfMonth()),
        to: formatDateForFilter(getTomorrowDate())
      }
    };
    setFormFilters(resetFormFilters);
    setApiFilters(cleanApiFilters({
      ...defaultApiFilters,
      date: {
        from: formatDateForApi(getFirstDayOfMonth()),
        to: formatDateForApi(getTomorrowDate()),
        action: 'between',
        valueLabel: `${formatDateForApi(getFirstDayOfMonth())} - ${formatDateForApi(getTomorrowDate())}`
      }
    }, defaultApiFilters));
    setCurrentPage(1);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setCurrentPage(1);
  };

  const handleGoToPageChange = (event) => {
    setGoToPage(event.target.value);
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(goToPage, 10);
    if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= Math.ceil(totalRecords / pageSize)) {
      setCurrentPage(pageNumber);
      setGoToPage(''); // Clear the input after navigating
    }
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(1, prevPage - 1));
  };

  const handleToggleIsToMe = () => {
    setIsToMe((prevIsToMe) => {
      const newIsToMe = !prevIsToMe;
      const newApiFilters = {
        ...apiFilters,
        isToMe: {
          action: '=',
          value: newIsToMe,
          valueLabel: newIsToMe
        }
      };
      setApiFilters(newApiFilters);
      fetchTransactions(newApiFilters, sortConfig);
      return newIsToMe;
    });
  };
  const columns = [
    { key: 'transactionId', label: 'Transaction ID' },
    { key: 'fromUserName', label: 'From Username' },
    { key: 'toUserName', label: 'To Username' },
    { key: 'amount', label: 'Amount', getDisplayValue: formatCurrency },
    { key: 'date', label: 'Date' }
  ];

  const updatedColumns = columns.filter(column => {
    if (isToMe && column.key === 'toUserName') {
      return false;
    }
    if (!isToMe && column.key === 'fromUserName') {
      return false;
    }
    return true;
  });

  const filterFields = [
    {
      type: 'text',
      label: 'Transaction ID',
      name: 'transactionId'
    },
    {
      type: 'text',
      label: 'Player Username',
      name: 'userName'
    },
    {
      type: 'text',
      label: 'Player ID',
      name: 'playerId'
    },
    {
      type: 'datetime-local',
      label: 'Date From',
      name: 'from'
    },
    {
      type: 'datetime-local',
      label: 'Date To',
      name: 'to'
    }
  ];

  return (
    <Layout>
      <Container>
        <Box display="flex" flexDirection="column" mb={2}>
          <Typography variant="h4" mb={2}>Transactions List</Typography>
          <Button
            variant="contained"
            sx={{
              backgroundColor: `${process.env.REACT_APP_SECONDARY_COLOR} !important`,
              color: '#000 !important',
              '&:hover': {
                backgroundColor: `${process.env.REACT_APP_SECONDARY_COLOR} !important`,
              },
            }}
            
            onClick={handleToggleIsToMe}
            fullWidth
          >
            Switch to {isToMe ? 'Transactions with Players' : 'Transactions with Parent'}
          </Button>
          <Card sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="h6">Summary</Typography>
            <Box mt={2}>
              <DailyPlayerTransactions
                sx={{ backgroundColor: '#f0f0f0' }}
                formatCurrency={formatCurrency}
                from={formFilters.date.from}
                to={formFilters.date.to}
              />
            </Box>
          </CardContent>
        </Card>
          {isPortrait && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setFiltersVisible(!filtersVisible)}
              startIcon={filtersVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              sx={{ mt: 2 }}
            >
              {filtersVisible ? 'Hide Filters' : 'Show Filters'}
            </Button>
          )}
        </Box>

        <DynamicListComponent
          filters={formFilters}
          filterFields={filterFields}
          onFilterChange={handleFilterChange}
          onDateChange={handleDateChange}
          onFilterSubmit={handleFilterSubmit}
          onResetFilters={handleResetFilters}
          filtersVisible={filtersVisible}
          data={transactions}
          columns={updatedColumns}
          copiableFields={['transactionId', 'fromUserName', 'toUserName']}
          sortableFields={[]}
          sortConfig={sortConfig}
          onSortChange={(key, direction) => {
            setSortConfig({ title: key, status: direction });
          }}
          pagination={{
            pageSize,
            currentPage,
            totalRecords,
            onPageChange: setCurrentPage,
            onPageSizeChange: handlePageSizeChange,
            onGoToPageChange: handleGoToPageChange,
            onGoToPage: handleGoToPage,
            onNextPage: handleNextPage,
            onPreviousPage: handlePreviousPage,
            goToPage,
            setGoToPage
          }}
        />


      </Container>
    </Layout>
  );
};

export default TransactionsList;
