import React, { useState, useEffect } from 'react';
import {
  Box,
  Modal,
  Typography,
  IconButton,
  Button,
  Snackbar,
  useMediaQuery
} from '@mui/material';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import LockIcon from '@mui/icons-material/Lock';
import BlockIcon from '@mui/icons-material/Block';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MoneyTransferModal from './MoneyTransferModal';
import PlayerChangePasswordModal from './PlayerChangePasswordModal';
import api from '../services/api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2
};

const PlayerActionsModal = ({ open, handleClose, formatCurrency, fetchBalance, player }) => {
  const [openMoneyTransferModal, setOpenMoneyTransferModal] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [initialUsername, setInitialUsername] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const isLandscape = useMediaQuery('(orientation: landscape)');

  useEffect(() => {
    if (player) {
      setInitialUsername(`${player.username} - ID: ${player.playerId}`);
    }
  }, [player]);

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbarMessage(`Copied: ${text}`);
    setSnackbarOpen(true);
  };

  const handleTransferMoney = () => {
    setOpenMoneyTransferModal(true);
  };
  const handleCloseMoneyTransferModal = () => {
    setOpenMoneyTransferModal(false);
  };
  const handleChangePassword = () => {
    setOpenChangePasswordModal(true);
  };
  const handleCloseChangePasswordModal = () => {
    setOpenChangePasswordModal(false);
  };

  const handleBlockPlayer = async () => {
    try {
      const response = await api.post('/players/block', {
        playerId: player.playerId
      });

      if (response.data.result) {
        setSnackbarMessage('Player blocked successfully');
      } else {
        setSnackbarMessage('Failed to block player');
      }
    } catch (error) {
      setSnackbarMessage('Failed to block player');
    } finally {
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Player Actions
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {player && (
            <Box mb={2}>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => handleCopyToClipboard(player.playerId)}>
                  <strong>Player ID:</strong> {player.playerId}
                </Typography>
                <IconButton onClick={() => handleCopyToClipboard(player.playerId)} size="small">
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" sx={{ cursor: 'pointer' }} onClick={() => handleCopyToClipboard(player.username)}>
                  <strong>Username:</strong> {player.username}
                </Typography>
                <IconButton onClick={() => handleCopyToClipboard(player.username)} size="small">
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          )}
          <Button
            onClick={handleTransferMoney}
            startIcon={<TransferWithinAStationIcon />}
            fullWidth
            sx={{
              backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
              color: process.env.REACT_APP_PRIMARY_BUTTON_COLOR,
              '&:hover': {
                backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER,
              },
            }}
            disabled={!player}
          >
            Transfer Money
          </Button>
          <Button
            onClick={handleChangePassword}
            startIcon={<LockIcon />}
            fullWidth
            sx={{
              backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
              color: process.env.REACT_APP_PRIMARY_BUTTON_COLOR,
              '&:hover': {
                backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER,
              },
            }}
            disabled={!player}
          >
            Change Password
          </Button>
          <Button
            onClick={handleBlockPlayer}
            startIcon={<BlockIcon />}
            fullWidth
            sx={{
              backgroundColor: '#B71C1C',
              color: '#FFFFFF',
              '&:hover': {
                backgroundColor: '#8B0000',
              },
            }}
            disabled={!player}
          >
            Block
          </Button>
          <Box mt={2}>
            <Button
              onClick={handleClose}
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_BG,
                color: process.env.REACT_APP_SECONDARY_BUTTON_COLOR,
                '&:hover': {
                  backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_HOVER,
                },
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      <MoneyTransferModal
        open={openMoneyTransferModal}
        handleClose={handleCloseMoneyTransferModal}
        setOpenMoneyTransferModal={setOpenMoneyTransferModal}
        fetchBalance={fetchBalance}
        formatCurrency={formatCurrency}
        initialUsername={initialUsername}
      />
      <PlayerChangePasswordModal
        open={openChangePasswordModal}
        handleClose={handleCloseChangePasswordModal}
        playerId={player?.playerId}
      />
      <Snackbar
        open={snackbarOpen}
        message={snackbarMessage}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={isLandscape ? { vertical: 'bottom', horizontal: 'center' } : { vertical: 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: process.env.REACT_APP_SECONDARY_COLOR,
            color: process.env.REACT_APP_PRIMARY_COLOR,
          }
        }}
      />
    </>
  );
};

export default PlayerActionsModal;
