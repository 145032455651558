import React, { useState } from 'react';
import {
  Menu,
  MenuItem,
  Box,
  IconButton,
  ListItemIcon,
  Snackbar,
  useMediaQuery
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import usFlag from '../assets/us-flag.png';
import frFlag from '../assets/fr-flag.png';
import tnFlag from '../assets/tn-flag.png';
import ChangePasswordModal from './ChangePasswordModal';

const AccountMenu = ({ user, logout }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const isLandscape = useMediaQuery('(orientation: landscape)');

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLanguageAnchorEl(null);
  };

  const handleLanguageMenu = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleCloseLanguageMenu = () => {
    setLanguageAnchorEl(null);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(user.username);
    setSnackbarMessage('Username copied to clipboard');
    setSnackbarOpen(true);
    setTimeout(() => setSnackbarOpen(false), 2000);
  };

  const handleOpenChangePasswordModal = () => {
    setOpenChangePasswordModal(true);
    handleClose();
  };

  const handleCloseChangePasswordModal = () => {
    setOpenChangePasswordModal(false);
  };

  const handleLanguageSelect = (language) => {
    setSnackbarMessage('Coming Soon!');
    setSnackbarOpen(true);
    handleCloseLanguageMenu();
    setTimeout(() => setSnackbarOpen(false), 2000);
  };

  return (
    <>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            marginTop: '12px',
            paddingTop: 0,
          },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 2 }}>
          <AccountCircle sx={{ fontSize: 60, color: 'gray' }} />
          <MenuItem
            onClick={handleCopyToClipboard}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              justifyContent: 'center',
              width: '100%',
              backgroundColor: '#f0f0f0',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#e0e0e0',
              },
            }}
          >
            {user.username}
            <ContentCopyIcon sx={{ fontSize: 16 }} />
          </MenuItem>
        </Box>
        <MenuItem onClick={handleOpenChangePasswordModal}>Change Password</MenuItem>
        <MenuItem onClick={handleLanguageMenu}>
          Language
          <img src={usFlag} alt="English" style={{ width: 24, marginLeft: 8 }} />
        </MenuItem>
        <Menu
          anchorEl={languageAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(languageAnchorEl)}
          onClose={handleCloseLanguageMenu}
        >
          <MenuItem onClick={() => handleLanguageSelect('fr')}>
            <ListItemIcon>
              <img src={frFlag} alt="French" style={{ width: 24 }} />
            </ListItemIcon>
            Français
          </MenuItem>
          <MenuItem onClick={() => handleLanguageSelect('ar')}>
            <ListItemIcon>
              <img src={tnFlag} alt="Arabic" style={{ width: 24 }} />
            </ListItemIcon>
            العربية
          </MenuItem>
        </Menu>
        <MenuItem onClick={logout} sx={{ color: 'red' }}>
          Logout
        </MenuItem>
      </Menu>
      <ChangePasswordModal
        open={openChangePasswordModal}
        handleClose={handleCloseChangePasswordModal}
        user={user}
        setSnackbarOpen={(message) => {
          setSnackbarMessage(message);
          setSnackbarOpen(true);
        }}
      />
      <Snackbar
        open={snackbarOpen}
        message={snackbarMessage}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: isLandscape ? 'bottom' : 'top', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: process.env.REACT_APP_SECONDARY_COLOR,
            color: process.env.REACT_APP_PRIMARY_COLOR,
          }
        }}
      />
    </>
  );
};

export default AccountMenu;
