import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import PlayersList from './components/PlayersList';
import TransactionsList from './components/TransactionsList';
import BetList from './components/BetList';
import PlayerInfo from './components/PlayerInfo'; // Import the new component
import { AuthProvider, AuthContext } from './context/AuthContext';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import TestDisconnect from './components/TestDisconnect';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, checkAuth } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const verifyAuth = async () => {
      await checkAuth();
      setLoading(false);
    };
    verifyAuth();
  }, [checkAuth]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

const PublicRoute = ({ component: Component, ...rest }) => {
  const { user } = React.useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? <Redirect to="/" /> : <Component {...props} />
      }
    />
  );
};

const App = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <PublicRoute path="/login" component={Login} />
              <PublicRoute path="/playerinfo/:_id" component={PlayerInfo} /> {/* Add this line */}
              <PrivateRoute exact path="/" component={Dashboard} />
              <PrivateRoute path="/players" component={PlayersList} />
              <PrivateRoute path="/transactions" component={TransactionsList} />
              <PrivateRoute path="/bets" component={BetList} />
              <PrivateRoute path="/testdisconnect" component={TestDisconnect} />
              <Route render={() => <Redirect to="/login" />} />
            </Switch>
          </Router>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
