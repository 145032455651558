import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
  Box
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { AuthContext } from '../context/AuthContext';
import NotificationModal from './NotificationModal';
import AddModal from './AddModal';
import NewPlayerModal from './NewPlayerModal';
import AccountMenu from './AccountMenu';
import api from '../services/api';
const logo = `${process.env.REACT_APP_LOGO}`;

const drawerWidth = 240;

const Header = ({ balance, mobileOpen, handleDrawerToggle, fetchBalance, formatCurrency }) => {
  const { user, logout } = useContext(AuthContext);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openNewPlayerModal, setOpenNewPlayerModal] = useState(false);
  const [newPlayer, setNewPlayer] = useState(null);
  const isLandscape = useMediaQuery('(orientation: landscape)');

  const handleNotificationClick = () => {
    setOpenNotificationModal(true);
  };

  const handleCloseNotificationModal = () => {
    setOpenNotificationModal(false);
  };

  const fetchNotificationsCount = async () => {
    try {
      const response = await api.post('/notifications', {
        start: 0,
        limit: 6,
        filter: {},
      });
      setUnreadNotifications(response.data.result.totalUnreadUserNotificationsCount);
    } catch (error) {
      console.error('Error fetching notifications count:', error);
    }
  };

  useEffect(() => {
    fetchNotificationsCount();
  }, []);

  const handleAddClick = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  const handleCloseNewPlayerModal = () => {
    setOpenNewPlayerModal(false);
    setNewPlayer(null);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem button component={Link} to="/dashboard">
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component={Link} to="/players">
          <ListItemText primary="View Players List" />
        </ListItem>
        <ListItem button component={Link} to="/transactions">
          <ListItemText primary="View Transactions List" />
        </ListItem>
        <ListItem button component={Link} to="/bets">
          <ListItemText primary="View Bet List" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/dashboard">
            <img src={logo} alt="logo" style={{ height: 32 }} />
          </Link>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}></Typography>
          {isLandscape && (
            <Box display="flex" alignItems="center" gap={2}>
              <IconButton color="inherit" aria-label="add" onClick={handleAddClick}>
                <AddCircleOutlineIcon />
              </IconButton>
              <AccountBalanceWalletIcon />
              <Typography variant="h6" noWrap>
                {balance}
              </Typography>
              <IconButton aria-label="show notifications" color="inherit" onClick={handleNotificationClick}>
                {unreadNotifications > 0 ? (
                  <Badge badgeContent={unreadNotifications} color="error">
                    <NotificationsIcon />
                  </Badge>
                ) : (
                  <NotificationsIcon />
                )}
              </IconButton>
              <AccountMenu user={user} logout={logout} />
            </Box>
          )}
          {!isLandscape && (
            <>
              <AccountBalanceWalletIcon />
              <Typography variant="h6" noWrap>
                {balance}
              </Typography>
              <IconButton aria-label="show notifications" color="inherit" onClick={handleNotificationClick}>
                {unreadNotifications > 0 ? (
                  <Badge badgeContent={unreadNotifications} color="error">
                    <NotificationsIcon />
                  </Badge>
                ) : (
                  <NotificationsIcon />
                )}
              </IconButton>
              <AccountMenu user={user} logout={logout} />
            </>
          )}

          <NotificationModal
            open={openNotificationModal}
            handleClose={handleCloseNotificationModal}
            setUnreadNotificationsCount={setUnreadNotifications}
          />
          <AddModal open={openAddModal} handleClose={handleCloseAddModal} fetchBalance={fetchBalance} formatCurrency={formatCurrency} />
          <NewPlayerModal open={openNewPlayerModal} handleClose={handleCloseNewPlayerModal} playerData={newPlayer} />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>

    </>
  );
};

export default Header;
