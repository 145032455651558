import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  OutlinedInput,
  Paper,
  Box,
  Collapse,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  InputAdornment
} from '@mui/material';
import { FirstPage, LastPage, ArrowUpward, ArrowDownward, ImportExport } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const DynamicListComponent = ({
  filters,
  filterFields,
  onFilterChange,
  onStatusChange,
  onDateChange,
  onFilterSubmit,
  onResetFilters,
  filtersVisible,
  data,
  columns,
  copiableFields = [],
  sortableFields = [],
  onRowClick = null,
  onSortChange = null,
  sortConfig,
  pagination: {
    pageSize,
    currentPage,
    totalRecords,
    onPageChange,
    onPageSizeChange,
    onGoToPageChange,
    onGoToPage,
    onNextPage,
    onPreviousPage,
    goToPage,
    setGoToPage
  }
}) => {
  const isWideScreen = useMediaQuery('(min-width:600px)');
  const isMobile = useMediaQuery('(max-width:600px)');
  const [copiedCell, setCopiedCell] = useState({ id: null, type: null });

  const handleCopyToClipboard = (text, id, type) => {
    navigator.clipboard.writeText(text);
    setCopiedCell({ id, type });
    setTimeout(() => {
      setCopiedCell({ id, type: null });
    }, 1500);
  };

  const isCopiable = (field) => copiableFields.includes(field);

  const handleSort = (key) => {
    let status = 'asc';
    if (sortConfig.title === key && sortConfig.status === 'asc') {
      status = 'desc';
    } else if (sortConfig.title === key && sortConfig.status === 'desc') {
      status = 'asc';
    }
    if (onSortChange) {
      onSortChange(key, status);
    }
  };

  return (
    <>
      <Collapse in={filtersVisible}>
        <Paper elevation={3} sx={{ padding: 2, marginBottom: 2 }}>
          <form onSubmit={onFilterSubmit}>
            <Grid container spacing={2}>
              {filterFields.map((field, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  {field.type === 'text' && (
                    <TextField
                      label={field.label}
                      name={field.name}
                      value={filters[field.name] || ''} // Handle undefined string values
                      onChange={onFilterChange}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  {field.type === 'datetime-local' && (
                    <TextField
                      label={field.label}
                      type={field.type}
                      name={field.name}
                      value={(filters.date && filters.date[field.name] ? filters.date[field.name].split(' ').join('T') : '')} // Handle undefined date values
                      onChange={onDateChange}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  )}
                  {field.type === 'date' && (
                    <TextField
                      label={field.label}
                      type={field.type}
                      name={field.name}
                      value={filters[field.name] || ''}
                      onChange={(e) => onDateChange(field.name, e.target.value)}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                    />
                  )}
                  {field.type === 'select-multiple' && (
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id={`${field.name}-label`}>{field.label}</InputLabel>
                      <Select
                        labelId={`${field.name}-label`}
                        name={field.name}
                        multiple
                        value={filters[field.name] || []}  // Fix to handle undefined and ensure it is an array
                        onChange={onStatusChange}
                        input={<OutlinedInput id="select-multiple-chip" label={field.label} />}
                        renderValue={(selected) => (
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => {
                              const option = field.options.find((opt) => opt.value === value);
                              return <Chip key={value} label={option ? option.label : ''} />;
                            })}
                          </Box>
                        )}
                      >
                        {field.options.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              ))}
              <Grid item xs={12}>
                {isWideScreen ? (
                  <Box display="flex" justifyContent="space-between">
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mr: 1 }}>
                      Apply Filters
                    </Button>
                    <Button type="button" variant="contained" color="secondary" fullWidth onClick={onResetFilters} sx={{ ml: 1 }}>
                      Reset Filters
                    </Button>
                  </Box>
                ) : (
                  <>
                    <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mb: 1 }}>
                      Apply Filters
                    </Button>
                    <Button type="button" variant="contained" color="secondary" fullWidth onClick={onResetFilters}>
                      Reset Filters
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Collapse>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.key}
                  onClick={() => sortableFields.includes(column.key) && handleSort(column.key)}
                  style={sortableFields.includes(column.key) ? { cursor: 'pointer' } : {}}
                >
                  <Box display="flex" alignItems="center">
                    <span>{column.label}</span>
                    {sortableFields.includes(column.key) && (
                      sortConfig.title === column.key ? (
                        sortConfig.status === 'asc' ? (
                          <ArrowUpward fontSize="small" />
                        ) : (
                          <ArrowDownward fontSize="small" />
                        )
                      ) : (
                        <ImportExport fontSize="small" />
                      )
                    )}
                  </Box>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                onClick={() => onRowClick && onRowClick(row)}
                style={onRowClick ? { cursor: 'pointer' } : {}}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.key}
                    className={
                      copiedCell.id === rowIndex && copiedCell.type === column.key ? 'copied' : ''
                    }
                    style={column.getCellStyle ? column.getCellStyle(row[column.key]) : {}}
                  >
                    {copiedCell.id === rowIndex && copiedCell.type === column.key ? (
                      <>
                        <ContentCopyIcon sx={{ fontSize: 14, marginRight: 0.5 }} /> Copied!
                      </>
                    ) : (
                      <span
                        onClick={(e) => {
                          if (isCopiable(column.key)) {
                            e.stopPropagation();
                            handleCopyToClipboard(row[column.key], rowIndex, column.key);
                          }
                        }}
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          cursor: isCopiable(column.key) ? 'pointer' : 'default',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {isCopiable(column.key) && (
                          <ContentCopyIcon sx={{ fontSize: 14, marginRight: 0.5 }} />
                        )}
                        {column.render ? column.render(row, row) : (column.getDisplayValue ? column.getDisplayValue(row[column.key]) : row[column.key])}
                      </span>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} flexDirection={isMobile ? 'column' : 'row'}>
        <FormControl variant="outlined" size="small" style={{ marginBottom: isMobile ? '8px' : '0' }}>
          <Select
            value={pageSize}
            onChange={onPageSizeChange}
          >
            {[10, 25, 50, 100, 1000].map(size => (
              <MenuItem key={size} value={size}>{size}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography style={{ marginBottom: isMobile ? '8px' : '0' }}>{`${(currentPage - 1) * pageSize + 1}-${Math.min(currentPage * pageSize, totalRecords)} of ${totalRecords}`}</Typography>
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={() => onPageChange(1)}
            disabled={currentPage === 1}
          >
            <FirstPage />
          </IconButton>
          <IconButton
            onClick={onPreviousPage}
            disabled={currentPage === 1}
          >
            <Typography>&lt;</Typography>
          </IconButton>
          <TextField
            value={goToPage}
            onChange={onGoToPageChange}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                onGoToPage();
                setGoToPage(''); // Clear the input after navigating
              }
            }}
            InputProps={{
              style: { color: 'black' },
              endAdornment: (
                <InputAdornment position="end">
                  <Button onClick={() => {
                    onGoToPage();
                    setGoToPage(''); // Clear the input after navigating
                  }}>Go</Button>
                </InputAdornment>
              ),
            }}
            size="small"
            variant="outlined"
            style={{ width: isMobile ? '128px' : '150px', margin: '0 8px' }}
          />
          <Typography>{`of ${Math.ceil(totalRecords / pageSize)}`}</Typography>
          <IconButton
            onClick={onNextPage}
            disabled={currentPage >= Math.ceil(totalRecords / pageSize)}
          >
            <Typography>&gt;</Typography>
          </IconButton>
          <IconButton
            onClick={() => onPageChange(Math.ceil(totalRecords / pageSize))}
            disabled={currentPage >= Math.ceil(totalRecords / pageSize)}
          >
            <LastPage />
          </IconButton>
        </Box>
      </Box>

      <style>{`
        .copied {
          background-color: #d4edda;
          transition: background-color 1.5s ease;
        }
      `}</style>
    </>
  );
};

export default DynamicListComponent;
