import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useHistory } from 'react-router-dom';

const TotalOpenBets = ({ count, sx }) => {
  const history = useHistory();

  const handleCardClick = () => {
    history.push({
      pathname: '/bets',
      state: {
        filter: {
          betStatus: {
            action: 'in',
            value: [1],
            valueLabel: 'Accepted'
          }
        }
      }
    });
  };

  return (
    <Card onClick={handleCardClick} sx={{ minHeight: '150px', cursor: 'pointer', ...sx }}>
      <CardContent>
        <Box display="flex" alignItems="center">
          <ReceiptLongIcon sx={{ fontSize: 50, color: process.env.REACT_APP_PRIMARY_COLOR }} />
          <Box ml={2}>
            <Typography variant="h6">Total Open Bets</Typography>
            <Typography variant="h4">{count}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TotalOpenBets;
