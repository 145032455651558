import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useHistory } from 'react-router-dom';

const TotalRegisteredPlayersToday = ({ count, sx }) => {
  const history = useHistory();

  const getTodayDate = () => {
    const now = new Date();
    const today = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0));
    return today.toISOString().split('.')[0].replace('T', ' ');
  };

  const handleCardClick = () => {
    history.push({
      pathname: '/players',
      state: {
        filter: {
          date: {
            from: getTodayDate(),
            to: new Date(Date.now() + 86400000).toISOString().split('.')[0].replace('T', ' ')
          }
        }
      }
    });
  };

  return (
    <Card
      onClick={handleCardClick}
      sx={{
        minHeight: '150px',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
        ...sx,
      }}
    >
      <CardContent>
        <Box display="flex" alignItems="center">
          <GroupAddIcon sx={{ fontSize: 50, color: process.env.REACT_APP_PRIMARY_COLOR }} />
          <Box ml={2}>
            <Typography variant="h6">Total Registered Players Today</Typography>
            <Typography variant="h4">{count}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TotalRegisteredPlayersToday;
