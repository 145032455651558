import React, { useState } from 'react';
import { Paper, Box, Typography } from '@mui/material';
import AddModal from './AddModal';
import AutoModeIcon from '@mui/icons-material/AutoMode';

console.log('environment variable:', process.env.REACT_APP_PRIMARY_COLOR);
const Footer = ({fetchBalance, formatCurrency}) => {
  const [openAddModal, setOpenAddModal] = useState(false);

  const handleAddClick = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
  };

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1300 }}
      elevation={3}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
          '&:hover': {
            backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER, // Darker green on hover
          },
        }}
        onClick={handleAddClick}
      >
        <Typography
          sx={{
            fontSize: '1.5rem',
            color: process.env.REACT_APP_SECONDARY_COLOR,
            textAlign: 'center',
            width: '100%',
            padding: '16px 0',
          }}
        >
          <AutoModeIcon sx={{ mr: 1 }} />
          New
        </Typography>
      </Box>
      <AddModal open={openAddModal} handleClose={handleCloseAddModal} fetchBalance = {fetchBalance} formatCurrency = {formatCurrency} />
    </Paper>
  );
};

export default Footer;
