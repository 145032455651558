import React, { useState, useEffect } from 'react';
import { Box, Modal, Typography, Button, IconButton, CircularProgress, useMediaQuery } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import QRCode from 'qrcode.react';
import MoneyTransferModal from './MoneyTransferModal';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '600px',
  maxHeight: '80vh',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2
};

const NewPlayerModal = ({ open, handleClose, playerData, loading, fetchBalance }) => {
  const [openMoneyTransferModal, setOpenMoneyTransferModal] = useState(false);
  const [initialUsername, setInitialUsername] = useState('');
  const isPortrait = useMediaQuery('(orientation: portrait)');

  useEffect(() => {
    if (playerData) {
      setInitialUsername(`${playerData.username} - ID: ${playerData.playerId}`);
    }
  }, [playerData]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard');
  };

  const handleDepositClick = () => {
    setOpenMoneyTransferModal(true);
  };

  const handleCloseMoneyTransferModal = () => {
    setOpenMoneyTransferModal(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              THANKS FOR THE REGISTRATION
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : playerData ? (
            <Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" p={1} bgcolor="#f0f0f0" borderRadius={1}>
                <Typography variant="body1"><strong>Login:</strong> {playerData.username}</Typography>
                <Typography variant="body1"><strong>Password:</strong> {playerData.password}</Typography>
                <IconButton onClick={() => copyToClipboard(`Username : ${playerData.username}   /   Password : ${playerData.password}`)}>
                  <FileCopyIcon />
                </IconButton>
              </Box>
              <Box mt={2} display="flex" flexDirection={isPortrait ? 'column' : 'row'} alignItems="center" gap={2}>
                {process.env.REACT_APP_QR_ENABLED === 'true' && (
                  <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" width={isPortrait ? '100%' : 'calc(50% - 8px)'}>
                    <QRCode
                      value={`${process.env.REACT_APP_FRONTEND_BASE_URL}/playerinfo/${playerData._id}`}
                      size={isPortrait ? 256 : 128} // Adjust size based on orientation
                      style={{ width: '100%', height: 'auto' }}
                    />
                    <Typography variant="body2" textAlign="center" mt={1}>
                      Please let the player scan this QR code in order to retrieve his new account credentials.
                    </Typography>
                  </Box>
                )}
                <Box display="flex" flexDirection="column" gap={2} width={isPortrait ? '100%' : 'calc(50% - 8px)'}>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{
                      backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
                      color: process.env.REACT_APP_PRIMARY_BUTTON_COLOR,
                      width: '100%',
                      '&:hover': {
                        backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER
                      },
                    }}
                    onClick={handleDepositClick}
                  >
                    Deposit
                  </Button>
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    sx={{
                      backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_BG,
                      color: process.env.REACT_APP_SECONDARY_BUTTON_COLOR,
                      width: '100%',
                      '&:hover': {
                        backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_HOVER,
                      },
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : (
            <Typography variant="body1">Failed to create player. Please try again.</Typography>
          )}
        </Box>
      </Modal>
      <MoneyTransferModal
        open={openMoneyTransferModal}
        handleClose={handleCloseMoneyTransferModal}
        setOpenMoneyTransferModal={setOpenMoneyTransferModal}
        fetchBalance={fetchBalance}
        formatCurrency={(value) => `${parseFloat(value).toLocaleString('fr-FR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} TND`}
        initialUsername={initialUsername}
      />
    </>
  );
};

export default NewPlayerModal;
