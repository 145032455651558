import React, { useState } from 'react';
import { Box, Modal, Typography, IconButton, Button } from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CloseIcon from '@mui/icons-material/Close';
import NewPlayerModal from './NewPlayerModal';
import NewPlayerFormModal from './NewPlayerFormModal';
import MoneyTransferModal from './MoneyTransferModal';
import api from '../services/api';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2
};

const AddModal = ({ open, handleClose, fetchBalance, formatCurrency }) => {
  const [openNewPlayerModal, setOpenNewPlayerModal] = useState(false);
  const [openNewPlayerFormModal, setOpenNewPlayerFormModal] = useState(false);
  const [openMoneyTransferModal, setOpenMoneyTransferModal] = useState(false);
  const [newPlayer, setNewPlayer] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSetNewPlayer = (player) => {
    setNewPlayer(player);
    setLoading(false);
    setOpenNewPlayerModal(true);
  };

  const handleCloseNewPlayerModal = () => {
    setOpenNewPlayerModal(false);
    setNewPlayer(null);
  };

  const handleFastCreate = async () => {
    handleClose();
    setOpenNewPlayerModal(true);
    setLoading(true);
    try {
      const response = await api.post('/players/create/fast', {});
      const data = await response.data;
      if (data.status === 'success') {
        handleSetNewPlayer(data.player);
      } else {
        console.error('Failed to create player');
        handleSetNewPlayer(null); // Ensure the player data is reset on failure
      }
    } catch (error) {
      console.error('Error creating player:', error);
      handleSetNewPlayer(null); // Ensure the player data is reset on failure
    }
  };

  const handleOpenNewPlayerFormModal = () => {
    handleClose();
    setOpenNewPlayerFormModal(true);
  };

  const handleCloseNewPlayerFormModal = () => {
    setOpenNewPlayerFormModal(false);
  };

  const handleOpenMoneyTransferModal = () => {
    handleClose();
    setOpenMoneyTransferModal(true);
  };

  const handleCloseMoneyTransferModal = () => {
    setOpenMoneyTransferModal(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Select an Option
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Button
            onClick={handleOpenNewPlayerFormModal}
            startIcon={<PersonAddIcon />}
            fullWidth
            sx={{
              backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
              color: process.env.REACT_APP_PRIMARY_BUTTON_COLOR,
              '&:hover': {
                backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER,
              },
            }}

          >
            New Player
          </Button>
          <Button
            onClick={handleOpenMoneyTransferModal}
            startIcon={<TransferWithinAStationIcon />}
            fullWidth
            sx={{
              backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
              color: process.env.REACT_APP_PRIMARY_BUTTON_COLOR,
              '&:hover': {
                backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER,
              },
            }}
          >
            Money Transfer
          </Button>
          <Button
            onClick={handleFastCreate}
            startIcon={<PersonAddAlt1Icon />}
            fullWidth
            sx={{
              backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
              color: process.env.REACT_APP_PRIMARY_BUTTON_COLOR,
              '&:hover': {
                backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER,
              },
            }}
          >
            New Player One Click
          </Button>
          <Box mt={2}>
            <Button
              onClick={handleClose}
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_BG,
                color: process.env.REACT_APP_SECONDARY_BUTTON_COLOR,
                '&:hover': {
                  backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_HOVER,
                },
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      <NewPlayerFormModal open={openNewPlayerFormModal} handleClose={handleCloseNewPlayerFormModal} handleSetNewPlayer={handleSetNewPlayer} />
      <NewPlayerModal open={openNewPlayerModal} handleClose={handleCloseNewPlayerModal} playerData={newPlayer} loading={loading}  fetchBalance = {fetchBalance} />
      <MoneyTransferModal open={openMoneyTransferModal} handleClose={handleCloseMoneyTransferModal} setOpenMoneyTransferModal={setOpenMoneyTransferModal} fetchBalance = {fetchBalance} formatCurrency = {formatCurrency} initialUsername = {false} />
    </>
  );
};

export default AddModal;
