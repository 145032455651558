import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import Layout from './Layout';
import api from '../services/api';
import BetDetailsModal from './BetDetailsModal';
import DynamicListComponent from './DynamicListComponent';
import {
  Container,
  Typography,
  Box,
  Button,
  useMediaQuery
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import betStatusOptions from '../constants/betStatusOptions';
import betTypes from '../constants/betTypes';
import getStatusStyles from '../constants/getStatusStyles';

const BetList = () => {
  const { user } = useContext(AuthContext);
  const [bets, setBets] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [betDetails, setBetDetails] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [goToPage, setGoToPage] = useState('');
  const location = useLocation();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [sortConfig, setSortConfig] = useState({ title: null, status: 'asc' });

  const isPortrait = useMediaQuery('(orientation: portrait)');

  useEffect(() => {
    if (isPortrait) {
      setFiltersVisible(false);
    }
  }, [isPortrait]);

  const formatDateForFilter = (date) => {
    return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss');
  };

  const getFirstDayOfMonth = () => {
    return moment().utc().startOf('month').toDate();
  };

  const getTomorrowDate = () => {
    return moment().utc().add(1, 'days').startOf('day').toDate();
  };

  const defaultApiFilters = {
    date: {
      action: "between",
      from: formatDateForFilter(getFirstDayOfMonth()),
      to: formatDateForFilter(getTomorrowDate()),
      valueLabel: `${formatDateForFilter(getFirstDayOfMonth())} - ${formatDateForFilter(getTomorrowDate())}`
    }
  };

  const [formFilters, setFormFilters] = useState({
    betId: '',
    playerId: '',
    playerUsername: '',
    betStatus: []
  });

  const [apiFilters, setApiFilters] = useState({
    ...defaultApiFilters
  });

  const cleanFormFilters = (filters) => {
    const cleanedFilters = {};
    if (filters.betId) {
      cleanedFilters.betId = { action: '=', value: filters.betId, valueLabel: filters.betId };
    }
    if (filters.playerId) {
      cleanedFilters.playerId = { action: '=', value: filters.playerId, valueLabel: filters.playerId };
    }
    if (filters.playerUsername) {
      cleanedFilters.playerUsername = { action: '=', value: filters.playerUsername, valueLabel: filters.playerUsername };
    }
    if (filters.betStatus && filters.betStatus.length > 0) {
      const valueLabel = filters.betStatus.map(status => {
        const option = betStatusOptions.find(opt => opt.value === status);
        return option ? option.label : status;
      }).join(', ');

      cleanedFilters.betStatus = {
        action: 'in',
        value: filters.betStatus,
        valueLabel: valueLabel,
        staticDataKey: 'betStatus'
      };
    }
    return cleanedFilters;
  };

  const cleanApiFilters = (filters, defaultApiFilters) => {
    const cleanedFilters = { ...filters };
    Object.keys(cleanedFilters).forEach(key => {
      if (!cleanedFilters[key].value) {
        delete cleanedFilters[key];
      }
    });
    return { ...defaultApiFilters, ...cleanedFilters };
  };

  const fetchBets = async (filters, sortConfig) => {
    try {
      const sort = sortConfig.title ? { title: sortConfig.title, status: sortConfig.status } : undefined;
      const response = await api.post(
        '/bets/list',
        {
          start: (currentPage - 1) * pageSize,
          limit: pageSize,
          filter: Object.keys(filters).length > 0 ? filters : {},
          sort: sort
        }
      );
      setBets(response.data.result.records);
      setTotalRecords(response.data.result.totalRecordsCount);
    } catch (error) {
      console.error('Error fetching bets:', error);
    }
  };

  const fetchBetDetails = async (betId) => {
    try {
      const response = await api.post('/bets/getodds', { betId });
      setBetDetails(response.data.result);
    } catch (error) {
      console.error('Error fetching bet details:', error);
    }
  };

  useEffect(() => {
    if (location.state && location.state.filter) {
      setFormFilters({
        betId: '',
        playerId: '',
        playerUsername: '',
        betStatus: location.state.filter.betStatus.value
      });
      setApiFilters(cleanApiFilters({
        betStatus: {
          action: "in",
          value: location.state.filter.betStatus.value,
          valueLabel: location.state.filter.betStatus.valueLabel,
          staticDataKey: 'betStatus'
        }
      }, defaultApiFilters));
    } else {
      setFormFilters({
        betId: '',
        playerId: '',
        playerUsername: '',
        betStatus: [],
        date: {
          from: formatDateForFilter(getFirstDayOfMonth()),
          to: formatDateForFilter(getTomorrowDate()),
        }
      });
      setApiFilters(cleanApiFilters({}, defaultApiFilters));
    }
    setIsInitialLoad(true); // Set initial load flag
  }, [location.state]);

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false); // Reset the flag after initial load
    } else {
      fetchBets(apiFilters, sortConfig);
    }
  }, [apiFilters, sortConfig, currentPage, pageSize]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFormFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleStatusChange = (event) => {
    const { value } = event.target;
    if (value.includes('selectAll')) {
      setFormFilters((prevFilters) => ({
        ...prevFilters,
        betStatus: betStatusOptions.filter(option => option.value !== 'selectAll').map(option => option.value)
      }));
    } else {
      setFormFilters((prevFilters) => ({
        ...prevFilters,
        betStatus: value
      }));
    }
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    const formattedValue = moment(value).utc().format('YYYY-MM-DD HH:mm:ss');
    setApiFilters((prevFilters) => ({
      ...prevFilters,
      date: {
        ...prevFilters.date,
        [name]: formattedValue,
        valueLabel: `${name === 'from' ? formattedValue : prevFilters.date.from} - ${name === 'to' ? formattedValue : prevFilters.date.to}`
      }
    }));
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    const cleanedFilters = cleanApiFilters({ ...cleanFormFilters(formFilters), ...defaultApiFilters }, defaultApiFilters);
    setApiFilters(cleanedFilters);
    setCurrentPage(1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(1, prevPage - 1));
  };

  const handleRowClick = (bet) => {
    fetchBetDetails(bet.betId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
  };

  const handleGoToPageChange = (event) => {
    setGoToPage(event.target.value);
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(goToPage, 10);
    if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= Math.ceil(totalRecords / pageSize)) {
      setCurrentPage(pageNumber);
      setGoToPage(''); // Clear the input after navigating
    }
  };

  const handleResetFilters = () => {
    setFormFilters({
      betId: '',
      playerId: '',
      playerUsername: '',
      betStatus: []
    });
    setApiFilters(cleanApiFilters({}, defaultApiFilters));
    setCurrentPage(1);
  };

  const formatCurrency = (value) => {
    return `${parseFloat(value).toLocaleString('fr-FR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} TND`;
  };

  const columns = [
    { key: 'betId', label: 'Bet ID', copiable: true },
    { key: 'playerUsername', label: 'Player Username', copiable: true },
    { key: 'betType', label: 'Bet Type', getDisplayValue: (value) => betTypes[value] },
    { key: 'date', label: 'Date' },
    { key: 'totalStake', label: 'Stake', getDisplayValue: formatCurrency },
    { key: 'potentialWinAmount', label: 'Potential Win', getDisplayValue: formatCurrency },
    { key: 'winAmount', label: 'Win Amount', getDisplayValue: formatCurrency },
    { key: 'betStatus', label: 'Status', getDisplayValue: (value) => value.charAt(0).toUpperCase() + value.slice(1), getCellStyle: (value) => getStatusStyles(value) },
    {
      key: 'isLive', label: 'Live/Pre', render: (value) => (
        value === "1" ? (
          <Typography sx={{ color: 'red', display: 'inline' }}>
            <span style={{ animation: 'blinker 1.5s linear infinite' }}>&#9679;</span> LIVE
          </Typography>
        ) : (
          <Typography sx={{ color: 'gray', display: 'inline' }}>
            PREMATCH
          </Typography>
        )
      )
    }
  ];

  const sortableFields = ['betId', 'playerUsername', 'betType', 'date', 'totalStake', 'potentialWinAmount', 'winAmount', 'betStatus'];

  const filterFields = [
    {
      type: 'text',
      label: 'Bet ID',
      name: 'betId'
    },
    {
      type: 'text',
      label: 'Player ID',
      name: 'playerId'
    },
    {
      type: 'text',
      label: 'Player Username',
      name: 'playerUsername'
    },
    {
      type: 'datetime-local',
      label: 'Date From',
      name: 'from'
    },
    {
      type: 'datetime-local',
      label: 'Date To',
      name: 'to'
    },
    {
      type: 'select-multiple',
      label: 'Bet Status',
      name: 'betStatus',
      options: betStatusOptions
    }
  ];

  if (!user) return <div>Loading...</div>;

  return (
    <Layout>
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h4">Bets List</Typography>
          <Button onClick={() => setFiltersVisible(!filtersVisible)} variant="contained" color="primary">
            {filtersVisible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            {filtersVisible ? 'Hide Filters' : 'Show Filters'}
          </Button>
        </Box>

        <DynamicListComponent
          filters={formFilters}
          filterFields={filterFields}
          onFilterChange={handleFilterChange}
          onStatusChange={handleStatusChange}
          onDateChange={handleDateChange}
          onFilterSubmit={handleFilterSubmit}
          onResetFilters={handleResetFilters}
          filtersVisible={filtersVisible}
          data={bets}
          columns={columns}
          copiableFields={['betId', 'playerUsername']}
          sortableFields={sortableFields}
          onRowClick={handleRowClick}
          sortConfig={sortConfig}
          onSortChange={(key, status) => {
            setSortConfig({ title: key, status });
          }}
          pagination={{
            pageSize,
            currentPage,
            totalRecords,
            onPageChange: setCurrentPage,
            onPageSizeChange: handlePageSizeChange,
            onGoToPageChange: handleGoToPageChange,
            onGoToPage: handleGoToPage,
            onNextPage: handleNextPage,
            onPreviousPage: handlePreviousPage,
            goToPage,
            setGoToPage
          }}
        />

        <BetDetailsModal
          open={modalOpen}
          onClose={handleCloseModal}
          betDetails={betDetails}
        />
      </Container>
    </Layout>
  );
};

export default BetList;
