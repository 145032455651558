import React from 'react';
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const getStatusStyles = (status) => {
  if (!status) {
    return { backgroundColor: '#e0e0e0', color: '#424242' };
  }
  switch (status.toLowerCase()) {
    case 'won':
      return { backgroundColor: '#e8f5e9', color: '#2e7d32' };
    case 'lost':
      return { backgroundColor: '#ffebee', color: '#c62828' };
    case 'accepted':
      return { backgroundColor: '#fffde7', color: '#f9a825' };
    case 'cashedout':
      return { backgroundColor: '#d5e2ef', color: '#4888bf' };
    default:
      return { backgroundColor: '#e0e0e0', color: '#424242' };
  }
};

const capitalizeFirstLetter = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

const BetDetailsModal = ({ open, onClose, betDetails }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 800,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
            overflowY: 'auto',
            maxHeight: '90vh',
            border: 'none',
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6" component="h2">
              Bet Details
            </Typography>
            <IconButton onClick={onClose} sx={{ width: 48, height: 48 }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Price</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Sport Name</TableCell>
                  <TableCell>Region Name</TableCell>
                  <TableCell>Competition Name</TableCell>
                  <TableCell>Match Name</TableCell>
                  <TableCell>Market Name</TableCell>
                  <TableCell>Selection Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {betDetails && Array.isArray(betDetails) && betDetails.length > 0 ? (
                  betDetails.map((detail) => (
                    <TableRow key={detail.oddId}>
                      <TableCell>{detail.price}</TableCell>
                      <TableCell style={getStatusStyles(detail.status)}>
                        {capitalizeFirstLetter(detail.status)}
                      </TableCell>
                      <TableCell>{detail.sportName}</TableCell>
                      <TableCell>{detail.regionName}</TableCell>
                      <TableCell>{detail.competitionName}</TableCell>
                      <TableCell>{detail.matchName}</TableCell>
                      <TableCell>{detail.marketName}</TableCell>
                      <TableCell>{detail.selectionName}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No details available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="center" mt={2}>
            <Button onClick={onClose} variant="contained" color="primary" sx={{ minWidth: 100, minHeight: 48 }}>
              Close
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default BetDetailsModal;
