// frontend/src/constants/betTypes.js
const betTypes = {
    1: 'Single',
    2: 'Multiple',
    3: 'System',
    4: 'Chain',
    5: 'Trixie',
    6: 'Yankee',
    7: 'PermedYankee',
    8: 'SuperYankee',
    9: 'Heinz',
    10: 'SuperHeinz',
    11: 'Goliath',
    12: 'Patent',
    13: 'PermedPatent',
    14: 'Lucky15',
    15: 'Lucky31',
    16: 'Lucky63',
    17: 'Alphabet',
    25: 'Gagnant',
    26: 'Place',
    27: 'CoupleGagnant',
    28: 'CouplePlace',
    29: 'CoupleOrdre',
    30: 'Trio',
    31: 'QuintePlus',
    32: 'TrioOrdre',
    50: 'BetBuilder',
    51: 'LineupBet'
  };
  
  export default betTypes;
  