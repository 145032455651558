import React, { useState, useEffect, useContext } from 'react';
import api from '../services/api';
import { AuthContext } from '../context/AuthContext';
import { Box, Toolbar, useMediaQuery } from '@mui/material';
import Footer from './Footer';
import Header from './Header';

const drawerWidth = 240;

const Layout = ({ children }) => {
  const { user, checkAuth } = useContext(AuthContext);
  const [balance, setBalance] = useState(user ? user.balance : null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isLandscape = useMediaQuery('(orientation: landscape)');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const formatCurrency = (value) => {
    return `${parseFloat(value).toLocaleString('fr-FR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} TND`;
  };
  const fetchBalance = async () => {
    try {
      const response = await api.get('/agent/balance', { withCredentials: true });
      const newBalance = response.data.balance;
      setBalance(formatCurrency(newBalance));
      checkAuth(); // Update the authentication state with the new balance
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  useEffect(() => {
    fetchBalance();
  }, []); // Empty dependency array to ensure this runs only once

  if (!user) return <div>Loading...</div>;

  return (
    <Box sx={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
      <Header
        balance={balance}
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
        fetchBalance = {fetchBalance}
        formatCurrency = {formatCurrency}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          overflowY: 'auto', // Allow vertical scrolling
          mb: isLandscape ? 0 : '56px', // Space for the footer, hide in landscape mode
        }}
      >
        <Toolbar />
        {children}
      </Box>
      {!isLandscape && (
        <Footer
        fetchBalance = {fetchBalance}
        formatCurrency = {formatCurrency}
        />
      )}
    </Box>
  );
};

export default Layout;
