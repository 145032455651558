// frontend/src/constants/getStatusStyles.js
const getStatusStyles = (status) => {
    switch (status.toLowerCase()) {
      case 'won':
        return { backgroundColor: '#e8f5e9', color: '#2e7d32' };
      case 'lost':
        return { backgroundColor: '#ffebee', color: '#c62828' };
      case 'accepted':
        return { backgroundColor: '#fffde7', color: '#f9a825' };
      case 'cashedout':
        return { backgroundColor: '#d5e2ef', color: '#4888bf' };
      default:
        return { backgroundColor: '#e0e0e0', color: '#424242' };
    }
  };
  
  export default getStatusStyles;
  