import React, { useState, useEffect } from 'react';
import { Box, Modal, Typography, Button, CircularProgress } from '@mui/material';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import api from '../services/api';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '400px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};
  
const ConfirmTransferModal = ({ open, handleClose, amount, username, playerId, type, handleBack, fetchBalance, formatCurrency }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (open) {
      setLoading(false);
      setSuccess(false);
      setError(null);
    }
  }, [open]);

  const handleConfirm = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.post(`/players/${type?.toLowerCase()}`, {
        amount,
        comment: null,
        playerId,
        currencyCode: 'TND',
        currency: 'TND',
        moneyStatus: 5,
      });
      const data = response.data;
      if (data.result) {
        setSuccess(true);
        fetchBalance();
      } else {
        setError(data.notification);
      }
    } catch (error) {
      console.error('Error during transfer:', error);
      setError([{ content: 'An error occurred. Please try again.' }]);
    } finally {
      setLoading(false);
    }
  };

  const resetModals = () => {
    handleClose();
    setLoading(false);
    setSuccess(false);
    setError(null);
  };

  return (
    <Modal
      open={open}
      onClose={resetModals}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress />
          </Box>
        ) : success ? (
          <>
            <Box display="flex" justifyContent="center" alignItems="center">
              <CheckCircleTwoToneIcon style={{ fontSize: 100, color: process.env.REACT_APP_PRIMARY_COLOR }} />
            </Box>
            <Typography variant="h6" textAlign="center">
              {type === 'Deposit' 
                ? `Deposit of ${formatCurrency(Math.abs(amount))} to ${playerId} - ${username.split(' - ID: ')[0]} is done successfully!`
                : `Withdraw of ${formatCurrency(Math.abs(amount))} from ${playerId} - ${username.split(' - ID: ')[0]} is done successfully!`}
            </Typography>
            <Button
              variant="contained"
              onClick={resetModals}
              sx={{
                backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_BG,
                color: process.env.REACT_APP_SECONDARY_BUTTON_COLOR,
                mt: 2,
                width: '100%',
                '&:hover': {
                  backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_HOVER,
                },
              }}
            >
              Close
            </Button>
          </>
        ) : (
          <>
            {error ? (
              <>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CancelTwoToneIcon style={{ fontSize: 100, color: '#B71C1C' }} />
                </Box>
                {error.map((err, index) => (
                  <Typography key={index} color="error" textAlign="center">
                    {err.content}
                  </Typography>
                ))}
                <Box display="flex" justifyContent="center" gap={2} mt={2} width="100%">
                  <Button
                    variant="contained"
                    onClick={handleBack}
                    sx={{
                      backgroundColor: 'transparent',
                      color: '#000000',
                      width: 'calc(50% - 8px)',
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    onClick={resetModals}
                    sx={{
                      backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_BG,
                      color: process.env.REACT_APP_SECONDARY_BUTTON_COLOR,
                      width: 'calc(50% - 8px)',
                      '&:hover': {
                        backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_HOVER,
                      },
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Typography variant="h6" textAlign="center">
                  {type === 'Deposit' 
                    ? `Are you sure to deposit  ${formatCurrency(Math.abs(amount))} to ${playerId} - ${username.split(' - ID: ')[0]}?`
                    : `Are you sure to withdraw  ${formatCurrency(Math.abs(amount))} from ${playerId} - ${username.split(' - ID: ')[0]}?`}
                </Typography>
                <Box display="flex" justifyContent="center" gap={2} mt={2} width="100%">
                  <Button
                    variant="contained"
                    onClick={handleBack}
                    sx={{
                      backgroundColor: 'transparent',
                      color: '#000000',
                      width: 'calc(50% - 8px)',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleConfirm}
                    sx={{
                      backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
                      color: process.env.REACT_APP_PRIMARY_BUTTON_COLOR,
                      width: 'calc(50% - 8px)',
                      '&:hover': {
                        backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER,
                      },
                    }}
                  >
                    Confirm
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ConfirmTransferModal;
