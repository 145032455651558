import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Modal, Typography, IconButton, TextField, Button, Card, CircularProgress, Autocomplete, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import api from '../services/api';
import ConfirmTransferModal from './ConfirmTransferModal';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '600px',
  maxHeight: '80vh',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

const parseSearchTerm = (term) => {
  if (term.includes(' - ID: ')) {
    const parts = term.split(' - ID: ');
    if (parts[1]) {
      return parts[1].trim();
    }
    return parts[0].trim();
  }
  if (term.includes(' ')) {
    return term.trim();
  }
  return term;
};

const MoneyTransferModal = ({ open, handleClose, setOpenMoneyTransferModal, fetchBalance, formatCurrency, initialUsername }) => {
  const [availableBalance, setAvailableBalance] = useState(0);
  const [playerBalance, setPlayerBalance] = useState(0);
  const [username, setUsername] = useState(initialUsername || '');
  const [amount, setAmount] = useState('');
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [balanceLoading, setBalanceLoading] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmType, setConfirmType] = useState(''); // New state for type
  const [amountError, setAmountError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const listBoxRef = useRef(null);
  const itemHeight = 48; // Adjust this if necessary
  const debounceTimeoutRef = useRef(null);
  const isPortrait = useMediaQuery('(orientation: portrait)');

  
  useEffect(() => {
    if (initialUsername) {
      setUsername(initialUsername);
    }
  }, [initialUsername]);
  const updateBalance = async () => {
    try {
      const response = await api.get('/agent/balance', { withCredentials: true });
      const newBalance = response.data.balance;
      setAvailableBalance(newBalance); // Set my balance in the card with the same format
    } catch (error) {
      console.error('Error fetching balance:', error);
    } finally {
      setBalanceLoading(false);
    }
  };

  const fetchPlayers = useCallback(async (searchTerm = '', isNextPage = false) => {
    if (!hasMore && isNextPage) return;

    try {
      setLoading(true);
      const parsedTerm = parseSearchTerm(searchTerm);
      const response = await api.post('/players/getbycurrentagent', {
        start: isNextPage ? page * 20 : 0,
        limit: 20,
        filter: {},
        isNextPage,
        searchBy: {
          getPlayersFromChildrenLists: parsedTerm,
        },
      });
      const data = response.data;
      if (isNextPage) {
        setPlayers((prev) => [...prev, ...data.result.records]);
        setPage((prev) => prev + 1);
        setTimeout(() => {
          if (listBoxRef.current) {
            const prevItemCount = (page - 1) * 20 + 1; // Adjust for initial scroll
            listBoxRef.current.scrollTop = prevItemCount * itemHeight;
          }
        }, 0);
      } else {
        setPlayers(data.result.records);
        setPage(1);
      }
      setHasMore(data.result.records.length === 20);
    } catch (error) {
      console.error('Error fetching players:', error);
    } finally {
      setLoading(false);
    }
  }, [page, hasMore]);

  useEffect(() => {
    if (open && initialLoad) {
      updateBalance();
      fetchPlayers(searchTerm);
      setInitialLoad(false);
    }
  }, [open, initialLoad, fetchPlayers, searchTerm]);

  const handleInputChange = (event) => {
    const newInputValue = event.target.value;
    setUsername(newInputValue);
    setSearchTerm(newInputValue);
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setPage(0);
      setHasMore(true);
      fetchPlayers(newInputValue);
    }, 1200);
  };

  const handlePlayerSelect = (event, value) => {
    if (value) {
      const fullText = `${value.username} - ID: ${value.playerId}`;
      setUsername(fullText);
      fetchPlayerBalance(value.playerId);
    }
  };

  const fetchPlayerBalance = async (playerId) => {
    try {
      const response = await api.post('/players/getbalance', { playerId });
      setPlayerBalance(response.data.result[0].balance);
    } catch (error) {
      console.error('Error fetching player balance:', error);
    }
  };

  const handleScroll = (event) => {
    const bottom = event.target.scrollHeight === event.target.scrollTop + event.target.clientHeight;
    if (bottom && hasMore && !loading) {
      fetchPlayers(searchTerm, true);
    }
  };

  const handleDeposit = () => {
    if (!amount || parseFloat(amount) <= 0) {
      setAmountError(true);
      return;
    }
    if (!username) {
      setUsernameError(true);
      return;
    }
    setConfirmType('Deposit'); // Set the type to "Deposit"
    setConfirmOpen(true);
    handleClose();
  };

  const handleWithdraw = () => {
    if (!amount || parseFloat(amount) <= 0) {
      setAmountError(true);
      return;
    }
    if (!username) {
      setUsernameError(true);
      return;
    }
    setConfirmType('Withdraw'); // Set the type to "Withdraw"
    setConfirmOpen(true);
    handleClose();
  };

  const handleClear = () => {
    setUsername('');
    setSearchTerm('');
    setPlayers([]);
    setPlayerBalance(0);
    setPage(0);
    setHasMore(true);
    fetchPlayers('');
  };

  const resetModals = () => {
    setConfirmOpen(false);
    setUsername('');
    setAmount('');
    setPlayerBalance(0);
    setPlayers([]);
    setSearchTerm('');
    setPage(0);
    setHasMore(true);
    setAmountError(false);
    setUsernameError(false);
  };

  const closeConfirmModal = () => {
    setConfirmOpen(false);
    // Reopen MoneyTransferModal with previous values
    setInitialLoad(false);
    setConfirmOpen(false);
    setAmountError(false);
    setUsernameError(false);
    fetchPlayers(searchTerm);
    // Reopen MoneyTransferModal
    setOpenMoneyTransferModal(true);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {balanceLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Money Transfer
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box display={{ xs: 'block', sm: 'flex' }} justifyContent="space-between" mb={2}>
                <Box display="flex" flexDirection="column" gap={2} flex="1" mb={{ xs: 2, sm: 0 }}>
                  <Card sx={{ p: 1.5, maxWidth: { xs: '100%', sm: '250px' }, fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                    <Typography variant="subtitle1">My Balance</Typography>
                    <Typography variant="body1">{formatCurrency(availableBalance)}</Typography>
                  </Card>
                  <Card sx={{ p: 1.5, maxWidth: { xs: '100%', sm: '250px' }, fontSize: { xs: '0.9rem', sm: '1rem' } }}>
                    <Typography variant="subtitle1">Player's Balance</Typography>
                    <Typography variant="body1">{formatCurrency(playerBalance)}</Typography>
                  </Card>
                </Box>
                <Box flex="1" display="flex" flexDirection="column" gap={2}>
                  <Autocomplete
                    options={players}
                    getOptionLabel={(option) => `${option.username} - ID: ${option.playerId}`}
                    onChange={handlePlayerSelect}
                    inputValue={username}
                    onInputChange={(event, newInputValue) => {
                      if (!initialUsername && newInputValue === "") {
                        handleClear();
                      } else {
                        setSearchTerm(newInputValue);
                      }
                    }}
                    ListboxProps={{ onScroll: handleScroll, ref: listBoxRef }}
                    openOnFocus
                    clearOnEscape
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Username"
                        fullWidth
                        margin="normal"
                        onChange={handleInputChange}
                        value={username}
                        error={usernameError}
                        helperText={usernameError ? 'Username is required' : ''}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                          style: {
                            borderColor: 'rgba(0, 0, 0, 0.23)',
                          },
                        }}
                        InputLabelProps={{
                          style: { color: 'rgba(0, 0, 0, 0.54)' },
                        }}
                      />
                    )}
                  />
                  <TextField
                    label="Amount"
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    fullWidth
                    margin="normal"
                    error={amountError}
                    helperText={amountError ? 'Amount must be greater than zero' : ''}
                    sx={{ maxWidth: { xs: '100%', sm: '400px' } }}
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                  />
                </Box>
              </Box>
              {isPortrait ? (
                <Box display="flex" flexDirection="column" gap={1}>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{
                      backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
                      color: process.env.REACT_APP_PRIMARY_BUTTON_COLOR,
                      width: '100%',
                      '&:hover': {
                        backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER,
                      },
                    }}
                    onClick={handleDeposit}
                  >
                    Deposit
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<RemoveIcon />}
                    sx={{
                      backgroundColor: '#B71C1C',
                      color: '#FFFFFF',
                      width: '100%',
                      '&:hover': {
                        backgroundColor: '#7F0000',
                      },
                    }}
                    onClick={handleWithdraw}
                  >
                    Withdraw
                  </Button>
                </Box>
              ) : (
                <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap={1}>
                  <Button
                    variant="contained"
                    startIcon={<RemoveIcon />}
                    sx={{
                      backgroundColor: '#B71C1C',
                      color: '#FFFFFF',
                      width: '48%',
                      '&:hover': {
                        backgroundColor: '#7F0000',
                      },
                    }}
                    onClick={handleWithdraw}
                  >
                    Withdraw
                  </Button>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{
                      backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_BG,
                      color: process.env.REACT_APP_PRIMARY_BUTTON_COLOR,
                      width: '48%',
                      '&:hover': {
                        backgroundColor: process.env.REACT_APP_PRIMARY_BUTTON_HOVER,
                      },
                    }}
                    onClick={handleDeposit}
                  >
                    Deposit
                  </Button>
                </Box>
              )}
              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_BG,
                    color: process.env.REACT_APP_SECONDARY_BUTTON_COLOR,
                    width: '100%',
                    maxWidth: { xs: '100%', sm: '400px' },
                    '&:hover': {
                      backgroundColor: process.env.REACT_APP_SECONDARY_BUTTON_HOVER,
                    },
                  }}
                  onClick={handleClose}
                >
                  Close
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
      <ConfirmTransferModal
        open={confirmOpen}
        handleClose={resetModals}
        amount={confirmType === 'Withdraw' ? -amount : amount} // Send negative amount for Withdraw
        username={username}
        playerId={username.split(' - ID: ')[1]}
        type={confirmType} // Pass the confirmType to ConfirmTransferModal
        handleBack={closeConfirmModal}
        fetchBalance={fetchBalance}
        formatCurrency={formatCurrency}
      />
    </>
  );
};

export default MoneyTransferModal;
