// src/components/DailyPlayerTransactions.js
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Card, CardContent, Grid, Box, CircularProgress } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { fetchTransactions } from '../utils/apiHelpers';
import { AuthContext } from '../context/AuthContext';

const DailyPlayerTransactions = ({ sx , formatCurrency, from, to }) => {
  const { user } = useContext(AuthContext);
  const [transactions, setTransactions] = useState({ in: 0, out: 0 });
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    const fetchAndSetTransactions = async () => {
      try {
        const data = await fetchTransactions(from, to);
        setTransactions(data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    if (user && from && to) {
      fetchAndSetTransactions();
    }
  }, [user, from, to]);

  const handleCardClick = () => {
    history.push({
      pathname: '/transactions',
      state: {
        filter: {
          date: {
            from,
            to
          }
        }
      }
    });
  };

  return (
    <Card
      onClick={handleCardClick}
      sx={{
        minHeight: '150px',
        cursor: 'pointer',
        transition: 'background-color 0.3s, transform 0.2s',
        '&:hover': {
          backgroundColor: '#f0f0f0',
          transform: 'scale(1.02)',
        },
        ...sx,
      }}
    >
      <CardContent>
        <Typography variant="h6">Turnover with Players</Typography>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="150px">
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2} mt={2}>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <FileUploadIcon fontSize="large" sx={{ color: process.env.REACT_APP_PRIMARY_COLOR }} />
                <Box ml={2}>
                  <Typography variant="subtitle1">Out</Typography>
                  <Typography variant="h6">{formatCurrency(transactions.out)}</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center">
                <FileDownloadIcon fontSize="large" sx={{ color: process.env.REACT_APP_SECONDARY_COLOR }} />
                <Box ml={2}>
                  <Typography variant="subtitle1">In</Typography>
                  <Typography variant="h6">{formatCurrency(transactions.in)}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default DailyPlayerTransactions;
