import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../services/api';


const TestDisconnect = () => {
  const history = useHistory();

  useEffect(() => {
    const disconnect = async () => {
      try {
        const response = await api.get('/auth/testdisconnect', { withCredentials: true });

        const data = await response.json(); // Parse the response body as JSON

        if (data.result === 'success') {
          history.push('/dashboard');
        } else {
          console.error('Failed to disconnect from old API');
          history.push('/login');
        }
      } catch (error) {
        console.error('Error disconnecting from old API:', error);
        history.push('/login');
      }
    };

    disconnect();
  }, [history]);

  return <div>Disconnecting...</div>;
};

export default TestDisconnect;
